
import { computed, defineComponent } from "vue";
import { useStore } from "vuex";

export default defineComponent({
  name: "ReservaLayout",
  setup() {
    const store = useStore();

    const establecimiento = computed(
      () => store.getters["establecimiento/getterEstablecimiento"]
    );

    const consultandoEstablecimiento = computed(
      () => store.getters["establecimiento/getterConsultandoEstablecimiento"]
    );


    const progresoReserva = computed(
      () => store.getters["reserva/getterProgresoReserva"]
    );

    const aforos = computed(() => store.getters["aforos/getterAforos"]);

    return {
      aforos,
      consultandoEstablecimiento,
      establecimiento,
      progresoReserva
    };
  },
});
