<template>
  <div class="page d-flex flex-row flex-column-fluid color-fondo-pantalla">
    <div id="kt_wrapper" class="d-flex flex-column flex-row-fluid wrapper">
      <div class="card mb-5 mb-xxl-8 custom-card">
        <div
          v-if="consultandoEstablecimiento"
          class="spinner-border"
          role="status"
        >
          <span class="visually-hidden">Loading...</span>
        </div>
        <div v-if="!consultandoEstablecimiento" class="card-body pt-9 pb-0">
          <!--begin::Details-->
          <div class="d-flex flex-wrap flex-sm-nowrap mb-3">
            <!--begin: Pic-->
            <div class="me-7 mb-4">
              <div
                class="symbol symbol-100px symbol-lg-160px symbol-fixed position-relative"
              >
                <img 
                  v-if="establecimiento.logo"
                  :src="establecimiento.logo"
                  :alt="establecimiento.nombre"
                />
               
                <div
                  class="position-absolute translate-middle bottom-0 start-100 mb-6 bg-success rounded-circle border border-4 border-white h-20px w-20px"
                ></div>
              </div>
            </div>
            <!--end::Pic-->

            <!--begin::Info-->
            <div class="flex-grow-1">
              <!--begin::Title-->
              <div
                class=" d-flex justify-content-between align-items-start flex-wrap mb-2"
              >
                <!--begin::User-->
                <div class="d-flex flex-column">
                  <!--begin::Name-->
                  <div class="d-flex align-items-center mb-2">
                    <div
                     
                      class="text-gray-800 text-hover-primary fs-2 fw-bolder me-1"
                      >{{ establecimiento?.nombre }}</div>
                    <div>
                      <span class="svg-icon svg-icon-1 svg-icon-primary">
                      
                        <img src="media/icons/duotone/Design/Verified.png">
                      </span>
                    </div>
                  </div>
                  <!--end::Name-->

                  <!--begin::Info-->
                  <div class="d-flex flex-wrap fw-bold fs-6 mb-4 pe-2">
                    <div
                      
                      class=" d-flex align-items-center text-gray-400 text-hover-primary me-5 mb-2"
                    >
                      <span class="svg-icon svg-icon-4 me-1">
                        <img src="media/icons/duotone/General/Marker1.png" />
                      </span>
                      {{ establecimiento?.descripcion }}
                    </div>
                    <div                      
                      class="d-flex align-items-center text-gray-400 text-hover-primary me-5 mb-2"
                    >
                      <span class="svg-icon svg-icon-4 me-1">
                        <img src="media/icons/duotone/Map/Marker1.png" />
                      </span>
                      {{ establecimiento?.direccion }}
                    </div>
                    <div
                      
                      class="d-flex align-items-center text-gray-400 text-hover-primary mb-2"
                    >
                      <span class="svg-icon svg-icon-4 me-1">
                        <img
                          src="media/icons/duotone/Communication/Active-call.png"
                        />
                      </span>
                      {{ establecimiento?.telefono }}
                    </div>
                  </div>
                  <!--end::Info-->
                </div>
                <!--end::User-->

                <!--begin::Actions-->
                <div class="d-flex my-4">
                  <!--begin::Menu-->
                  <div class="me-0">
                    <!--begin::Progress-->
                    <div
                      class=" d-flex align-items-center w-200px w-sm-300px flex-column  mt-3"
                    >
                      <div
                        class=" d-flex justify-content-between  w-100 mt-auto  mb-2"
                      >
                        <span class="fw-bold fs-6 text-gray-400"
                          >Proceso Reserva</span
                        >
                        <span class="fw-bolder fs-6">{{  Math.ceil((progresoReserva*100)/3)}}%</span>
                      </div>

                      <div class="h-5px mx-3 w-100 bg-light mb-3">
                        <div
                          class="bg-success rounded h-5px"
                          role="progressbar"
                          :style="{width: Math.ceil((progresoReserva* 100)/3) + '%'}"
                          :aria-valuenow="progresoReserva"
                          aria-valuemin="0"
                          aria-valuemax="3"
                        ></div>
                      </div>
                    </div>
                    <!--end::Progress-->
                  </div>
                  <!--end::Menu-->
                </div>
                <!--end::Actions-->
              </div>
              <!--end::Title-->

              <!--begin::Stats-->
              <div class="d-flex flex-wrap flex-stack">
                <!--begin::Wrapper-->
                <div class="d-flex flex-column flex-grow-1 pe-8">
                  <!--begin::Stats-->
                  <div
                    class="d-flex flex-wrap"
                    v-for="aforo in aforos"
                    :key="aforo.id"
                  >
                    <!--begin::Stat-->
                    <div
                      class="
                        border border-gray-300 border-dashed
                        rounded
                        min-w-125px
                        py-3
                        px-4
                        me-6
                        mb-3
                      "
                    >
                      <!--begin::Number-->
                      <div class="d-flex align-items-center">
                        <div class="fs-4 fw-bolder" data-kt-countup="true">
                          Apertura
                        </div>
                      </div>
                      <!--end::Number-->

                      <!--begin::Label-->
                      <div class="fw-bold fs-6 text-gray-400">
                        {{ aforo.horaApertura }}
                      </div>
                      <!--end::Label-->
                    </div>
                    <!--end::Stat-->

                    <!--begin::Stat-->
                    <div
                      class="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3"
                    >
                      <!--begin::Number-->
                      <div class="d-flex align-items-center">
                        <div class="fs-4 fw-bolder" data-kt-countup="true">
                          Cierre
                        </div>
                      </div>
                      <!--end::Number-->

                      <!--begin::Label-->
                      <div class="fw-bold fs-6 text-gray-400">
                        {{ aforo.horaCierre }}
                      </div>
                      <!--end::Label-->
                    </div>
                    <!--end::Stat-->
                  </div>
                  <!--end::Stats-->
                </div>
                <!--end::Wrapper-->
              </div>
              <!--end::Stats-->
            </div>
            <!--end::Info-->
          </div>
          <!--end::Details-->

          <!--begin::Navs-->
          <div class="d-flex overflow-auto h-55px">
            <ul
              class=" nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap"
            >
              <!--begin::Nav item-->
              <li class="nav-item" >
                <router-link
                  :to="{ name: 'informacion-inicial' }"
                  class="nav-link text-active-primary me-6 disabled"
                  active-class="active"
                >
                  Información Inicial
                </router-link>
              </li>
              <!--end::Nav item-->
              <!--begin::Nav item-->
              <li class="nav-item">
                <router-link
                  class="nav-link text-active-primary me-6 disabled"
                  :to="{ name: 'disponibilidad' }"
                  active-class="active"
                >
                  Disponibilidad
                </router-link>
              </li>
              <!--end::Nav item-->
              <!--begin::Nav item-->
              <li class="nav-item">
                <router-link
                  class="nav-link text-active-primary me-6 disabled"
                   :to="{ name: 'informacion-reserva' }"
                  active-class="active"
                >
                  Reserva
                </router-link>
              </li>
              <!--end::Nav item-->
              <!--begin::Nav item-->
              <li class="nav-item">
                <router-link
                  class="nav-link text-active-primary me-6 disabled"
                  :to="{ name: 'finalizar-reserva' }"
                  active-class="active"
                >
                  Finalizar
                </router-link>
              </li>
              <!--end::Nav item-->
            </ul>
          </div>
          <!--begin::Navs-->
        </div>
      </div>
      <!--end::Navbar-->
      <router-view></router-view>
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent } from "vue";
import { useStore } from "vuex";

export default defineComponent({
  name: "ReservaLayout",
  setup() {
    const store = useStore();

    const establecimiento = computed(
      () => store.getters["establecimiento/getterEstablecimiento"]
    );

    const consultandoEstablecimiento = computed(
      () => store.getters["establecimiento/getterConsultandoEstablecimiento"]
    );


    const progresoReserva = computed(
      () => store.getters["reserva/getterProgresoReserva"]
    );

    const aforos = computed(() => store.getters["aforos/getterAforos"]);

    return {
      aforos,
      consultandoEstablecimiento,
      establecimiento,
      progresoReserva
    };
  },
});
</script>

<style scoped>
.color-fondo-pantalla {
  background-color: #f2f3f5;
}
.custom-card {
  margin: 17px 17px 0px 17px;
  background-color: #fff;
}

.disabled {
  pointer-events: none;
}
</style>